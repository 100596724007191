import "./panel_description.scss";

const PanelDescription = ({ heading, subtitle, number, direction }) => {
  if (direction) {
    return (
      <div className="bipanel-container">
        <div className="bipanel-element bipanel-text">
          <div>
            <h3>{heading}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
        <div className="bipanel-element bipanel-supplement">
          <div className="number-circle color-2 border-color-2">
            <div className="number">{number}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bipanel-container-left">
        <div className="bipanel-element bipanel-supplement-left">
          <div className="number-circle color-5 border-color-5">
            <div className="number">{number}</div>
          </div>
        </div>
        <div className="bipanel-element bipanel-text">
          <div>
            <h3>{heading}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    );
  }
};

export default PanelDescription;
