import React from "react";
import "./header.scss";

import LogoWide from "../static/images/logo_wide.png";

const Header = () => {
  return (
    <div className="header-container">
      <div className="logo-wide-container">
        <img className="logo-wide-source" src={LogoWide} alt="Job 'Unt" />
      </div>

      <div className="sectional-container">
        <div className="sectional">
          <a href="https://medium.com/@prateekseth97/why-we-started-jobunt-fabf853573bb">About Us</a>
        </div>
      </div>

      <div className="sectional-container">
        <div className="sectional">
          <a href="https://medium.com/@prateekseth97">Blog</a>
        </div>
      </div>

      <div className="sectional-container">
        <div className="sectional">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdmD9ItUF7fQJzgGdZ_RcyBLcwRAldorObH2Hb0n0h_fSicEQ/viewform?usp=sf_link">Join the Team</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
