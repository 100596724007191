import "./static/styles/index.scss";

import Header from "./components/Header";
import Introduction from "./components/Introduction";
import PanelDescription from "./components/PanelDescription";
import Button from "@mui/material/Button";

function App() {
  return (
    <div className="App">
      <div className="main-container">
        <Header />
        <Introduction />
        <h1 className="functionality-header"> What Makes Us Different </h1>
        <PanelDescription
          heading="Find open internships"
          subtitle="Open positions are scraped directly from the career pages of the companies in our databases. We present all that information in a standardized format to help you discover relevant positions and apply faster."
          number={1}
          direction={true}
        />
        <PanelDescription
          heading="Parse keywords"
          subtitle="Set the right foot forward by automatically parsing relevant keywords in the job description and incorporating that into your resume. This will increase your likelihood of getting past ATS and into the view of a recruiter."
          number={2}
          direction={false}
        />
        <PanelDescription
          heading="Reach out to verified leads"
          subtitle="We offer recruiter and hiring manager contact information so that you can pitch yourself with an email directly to the people looking at your resumes. Separate yourself from everyone else."
          number={3}
          direction={true}
        />
        <PanelDescription
          heading="Track your progress"
          subtitle="Manage your application cycle using our built in tracker to manager your applications and points of contact. Keep track of the number of companies you applied to, how long it's been, and if your emails are getting responses."
          number={4}
          direction={false}
        />
        <PanelDescription
          heading="Search your local region"
          subtitle="Find internships that no one else knows about by reaching out directly to smaller companies without structured internship programs. Our platform will help you find the companies you are looking for, generate leads, and create a good pitch so that you can get work experience when all else fails."
          number={5}
          direction={true}
        />
        <div className="end-container">
          <div>
            <h1> What are you waiting for? </h1>
            <Button
              variant="contained"
              href="https://docs.google.com/forms/d/e/1FAIpQLScHKofqMeV70ytOkI2qS96XMrzD73IIjPSRSg7xhRqRCH6JSQ/viewform?usp=sf_link"
              sx={{
                marginTop: "20px",
                height: "50px",
                width: "200px",
                backgroundColor: "#f17300", // Change the background color to orange
                "&:hover": {
                  backgroundColor: "darkorange", // Change the hover color to a darker shade of orange
                },
              }}
            >
              Join the Waitlist
            </Button>
          </div>
        </div>
        <p className="copyright-notice">&copy; 2023 Prateek Seth. All rights reserved.</p>
      </div>
    </div>
  );
}

export default App;
