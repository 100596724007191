import "./introduction.scss";
import DemoScreen from "../static/images/app_demo_screen.png";
import Button from "@mui/material/Button";

const Introduction = () => {
  return (
    <div className="grid-container">
      <div className="grid-element demo-screen">
        <img src={DemoScreen} alt="" />
      </div>
      <div className="grid-element pitch-block">
        <div className="inner-pitch-block">
          <h1 className="pitch-title">Internship Hunt.</h1>
            <p>With the tools you need to finally get noticed.</p>
          <Button
            variant="contained"
            href="https://docs.google.com/forms/d/e/1FAIpQLScHKofqMeV70ytOkI2qS96XMrzD73IIjPSRSg7xhRqRCH6JSQ/viewform?usp=sf_link"
            sx={{
              marginTop: "20px",
              height: "50px",
              width: "200px",
              backgroundColor: "#f17300", // Change the background color to orange
              "&:hover": {
                backgroundColor: "darkorange", // Change the hover color to a darker shade of orange
              },
            }}
          >
            Join the Waitlist
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
